<template>
  <Form
    :submit="handleSubmit"
    :initialValues="prepareInitialValues(initialValues)"
    @change="handleFormChange"
  >
      <div class="form-narrow">
        <div class="flex items-center justify-end mb-2">
          <toggle-input v-model="useManualIdInput" size="small" />
          <span class="text-black text-sm font-600 font-inter ml-4">manual id input</span>
        </div>

        <label class="form-section-title">main information</label>

        <div class="form-row">
          <div class="w-1/2 pr-2">
            <TextField v-if="useManualIdInput" name="personId" label="person id*" :validate="composeValidators(required, uuid)" />
            <SelectInput v-else name="personId" label="person*" :options="persons" :validate="required" no-clear />
          </div>
          <div class="w-1/2 pl-2">
            <SelectInput name="relationshipType" label="relationship type*" :options="types" :validate="required" no-clear />
          </div>
        </div>
        <div>
          <TextField v-if="useManualIdInput" name="leaseId" label="lease id*" :validate="composeValidators(required, uuid)" />
          <div v-else>
            <div>
              <SelectInput name="leaseId" label="lease*" :options="leases" :validate="required" no-clear />
            </div>
            <div v-if="selectedLease.communityName" class="form-hint">
              <span class="pr-4">community: {{selectedLease.communityName}}</span><span>communityId: {{selectedLease.communityId}}</span>
            </div>
            <div v-if="selectedLease.customerName" class="form-hint">
              <span class="pr-4">customer: {{selectedLease.customerName}}</span><span>customerId: {{selectedLease.customerId}}</span>
            </div>
          </div>
        </div>

        <label class="form-section-title mt-8">supporting information</label>

        <div class="form-row supporting-entity-option">
          <TextField v-if="useManualIdInput" name="membershipId" :validate="uuid" hint="(optional) a new membership will be created if nothing is specified" />
          <SelectInput
            v-else
            name="membershipId"
            :options="memberships"
            hint="(optional) a new membership will be created if nothing is specified"
          />
        </div>

        <ModalFooter :footer="footer" :tertiary="cancel" />
      </div>
  </Form>
</template>

<script>
  import ModalNavigation from "@/mixins/ModalNavigation";
  import ValidatorMixin from "@/components/form/ValidatorMixin";
  import NotifyMixin from "@/mixins/NotifyMixin";
  import Form from "@/components/form/Form";
  import TextField from "@/components/form/TextField";
  import SelectInput from "@/components/form/SelectInput";
  import ModalFooter from "@/components/ui/modals/ModalFooter";
  import ToggleInput from "@/components/ui/ToggleInput";
  import ProfilesMixin from "./ProfilesMixin";

  export default {
    name: "ProfileForm",
    components: {
      TextField,
      SelectInput,
      ModalFooter,
      Form,
      ToggleInput,
    },
    mixins: [
      ModalNavigation,
      ValidatorMixin,
      NotifyMixin,
      ProfilesMixin,
    ],
    props: {
      onSubmit: {
        type: Function,
        required: true,
      },
      initialValues: {
        type: Object,
        default: () => ({})
      },
      loading: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    emits: ['close'],
    data() {
      return {
        types: [],
        communities: [],
        useManualIdInput: false,
        formValues: {},
        footer: {
          primaryButton: 'save',
          tertiaryButton: 'cancel'
        },
      }
    },
    computed: {
      selectedLease() {
        return this.leases.find(({ key }) => key === this.formValues.leaseId) || {};
      },
    },
    mounted() {
        Promise.all([
            this.fetchLeases(),
            this.fetchMemberships(),
            this.fetchPersons(),
            this.fetchRelationshipTypes(),
        ]);
    },
    methods: {
      handleFormChange(state) {
        this.formValues = state.values;
      },

      fetchRelationshipTypes() {
        this.$raaDataProvider.getList('relationshipTypes', {size: 999})
          .then((result) => {
              this.types = result.map(({type}) => ({key: type, value: type}));
          })
          .catch((err) => this.notifyError(err.message));
      },

      prepareInitialValues(initialValues = {}) {
        return {
          ...initialValues,
          personId: initialValues.person?.id,
        }
      },

      handleSubmit({ membershipId, personId, leaseId, relationshipType }) {
        const supportingData = membershipId ? { membershipId } : {};

        this.onSubmit({
          personId,
          leaseId,
          relationshipType,
          ...supportingData,
        });
      },

      cancel(e) {
        e.preventDefault();
        this.$emit('close');
      },
    }
  }
</script>

<style scoped>
  .supporting-entity-option {
    flex-direction: column;
  }
</style>
